<template>
  <h3 :class="$style['heading-3']">
    <slot />
  </h3>
</template>

<style lang="scss" module>
.heading-3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -3%;

  @media screen and (max-width: $md) {
    font-size: 1.75rem;
    letter-spacing: -1%;
  }

  @media screen and (max-width: $sm) {
    font-size: 1.325rem;
    line-height: 110%;
    letter-spacing: -2%;
  }
}
</style>
