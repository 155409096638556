<template>
  <PrimaryButton
    :class="$style.btn"
    :size="'xl'"
    :disabled="disabled"
  >
    <span :class="[$style['phrase'], $style['left-phrase']]">
      {{ toLocalNumber(totalAmount) }} товаров
    </span>
    {{ content }}
    <span :class="[$style['phrase'], $style['right-phrase']]">
      {{ toLocalNumber(totalCost) }} ₽
    </span>
  </PrimaryButton>
</template>

<script lang="ts" setup>
import PrimaryButton from "~/components/Shared/Button/PrimaryButton.vue";

const props = defineProps<{
  content: string;
  totalAmount: number;
  totalCost: number;
  disabled?: boolean;
}>();
const { content } = toRefs(props);
</script>

<style lang="scss" module>
.btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 0 calc(var(--spacing-2xl) + 70px);
}
.phrase {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  font-size: 0.75rem;
  color: var(--general-transparent-light-50);
  text-align: center;
}
.left-phrase,
.right-phrase {
  text-wrap: nowrap;
}
.left-phrase {
  left: var(--spacing-2xl);
}
.right-phrase {
  right: var(--spacing-2xl);
}
</style>
