<template>
  <div :class="$style.formContainer">
    <!-- ID -->
    <SharedInputError v-if="isUserIdError">
      {{ t("errors.input_uid") }}
    </SharedInputError>
    <Input
      v-model="bulldropInputValueId"
      :placeholder="t('errors.input_user_bulldrop_id')"
      size="l"
      type="number"
    />
    <!-- General error -->
    <GeneralError v-if="isError">
      {{ t("errors.order_error") }}
    </GeneralError>
    <GeneralError v-if="errorText">
      {{ errorText }}
    </GeneralError>
  </div>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import GeneralError from "./GeneralError.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const { isUserIdError, bulldropInputValueId } = storeToRefs(productsStore);
const isError = ref(false);
const errorText = ref<string | null>(null);

productsStore.initOrderFormValues();
</script>

<style lang="scss" module>
.formContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
</style>
