<template>
  <div :class="$style.formContainer">
    <div>
      <!-- Email -->
      <SharedInputError v-if="isUserMailError && !userStore.userData?.email">
        {{ t("errors.input_user_mail") }}
      </SharedInputError>
      <Input
        v-if="!userStore.userData?.email"
        v-model="inputValueMail"
        :has-error="isUserMailError"
        :placeholder="t('errors.input_user_mail')"
        size="l"
      />
    </div>
    <div>
      <Hint />
      <!-- General error -->
      <GeneralError v-if="isError">
        {{ t("errors.order_error") }}
      </GeneralError>
      <GeneralError v-if="errorText">
        {{ errorText }}
      </GeneralError>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import GeneralError from "./GeneralError.vue";
import Hint from "./PubgForm/Hint.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const userStore = useUserStore();
const { inputValueMail, isUserMailError, isError, errorText } =
  storeToRefs(productsStore);

productsStore.initOrderFormValues();

onUnmounted(() => {
  isUserMailError.value = false;
  isError.value = false;
  errorText.value = "";
});
</script>

<style lang="scss" module>
.formContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
</style>
