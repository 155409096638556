<template>
  <PrimaryButton
    :class="$style.btn"
    :size="'xl'"
    :disabled="disabled"
  >
    {{ content }}
  </PrimaryButton>
</template>

<script lang="ts" setup>
import PrimaryButton from "~/components/Shared/Button/PrimaryButton.vue";

const props = defineProps<{
  content: string;
  disabled?: boolean;
}>();
const { content } = toRefs(props);
</script>

<style lang="scss" module>
.btn {
  position: relative;
  display: block;
  width: 100%;
  margin: auto;
  padding: 0 calc(var(--spacing-2xl) + 70px);
}
</style>
