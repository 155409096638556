<template>
  <div :class="$style.formContainer">
    <!-- General error -->
    <GeneralError v-if="isError">
      {{ t("errors.order_error") }}
    </GeneralError>
    <GeneralError v-if="errorText">
      {{ errorText }}
    </GeneralError>
  </div>
</template>

<script lang="ts" setup>
import GeneralError from "./GeneralError.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const { isUserMailError, isError, errorText } = storeToRefs(productsStore);
productsStore.initOrderFormValues();

onUnmounted(() => {
  isUserMailError.value = false;
  isError.value = false;
  errorText.value = "";
});
</script>

<style lang="scss" module>
.formContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
</style>
