<template>
  <div :class="$style['order-btn-wrapper']">
    <slot />
  </div>
</template>

<style lang="scss" module>
.order-btn-wrapper {
  margin-top: var(--spacing-2lg);
  @media screen and (max-width: $sm) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-top: 0;
    padding: var(--spacing-4xl) var(--spacing-2lg);
    border-radius: var(--radius-6xl) var(--radius-6xl) 0 0;
    background: var(--general-underlay-4-lvl);
  }
}
</style>
