<template>
  <section
    :class="[$style['order-card'], compact && $style.compact]"
    data-test="order-card"
  >
    <div :class="$style['product-data']">
      <div :class="$style['product-picture-wrapper']">
        <img
          v-if="product.picture"
          :src="product.picture"
          :class="$style['product-picture']"
        />
        <img
          v-else
          src="@/assets/product-img.webp"
          :class="$style['product-picture']"
        />
        <SharedDiscount
          v-if="compact && product.discountPercent"
          :class="$style['product-discount']"
          size="s"
        >
          {{ product.discountPercent }}%
        </SharedDiscount>
      </div>
      <div :class="$style['product-titles']">
        <h5 :class="$style['product-name']">{{ product.title }}</h5>
        <p
          v-if="amount"
          :class="$style.subtitle"
        >
          {{ toLocalNumber(totalPrice) }}
          ₽
          <span
            v-if="totalOldPrice"
            :class="$style['price-discount']"
          >
            {{ toLocalNumber(totalOldPrice) }} ₽
          </span>
        </p>
      </div>
    </div>
    <SecondaryButton
      v-if="!amount || !product.isActive"
      :disabled="!product.isActive"
      :size="compact ? 's' : 'xl'"
      :class="[$style['price-btn'], compact && $style['compact-counter']]"
      @click="checkMinValue"
    >
      <template v-if="product.isActive">
        {{ toLocalNumber(price) }} ₽
        <span
          v-if="oldPrice"
          :class="$style['price-discount']"
        >
          {{ toLocalNumber(oldPrice) }} ₽
        </span>
      </template>
      <template v-else>{{ $t("products.products_order.sold_out") }}</template>
    </SecondaryButton>
    <Counter
      v-else
      v-model="amount"
      :min="product.minQuantity"
      :max="product.maxQuantity"
      :size="compact ? 's' : 'l'"
      :class="[
        $style['order-card-counter'],
        compact && $style['compact-counter'],
      ]"
      @keydown="preventDecimalInput"
    />
  </section>
</template>

<script lang="ts" setup>
import Counter from "~/components/Shared/Product/Counter.vue";
import SecondaryButton from "~/components/Shared/Button/SecondaryButton.vue";
import type { Product } from "~/api_gen";

const emit = defineEmits<{
  change: [{ productId: number; newAmount: number }];
}>();
const props = withDefaults(
  defineProps<{
    product: Product;
    initialAmount?: number;
    compact?: boolean;
  }>(),
  {
    initialAmount: 0,
    compact: false,
  },
);
const { product, initialAmount, compact } = toRefs(props);
const amount = ref(props.initialAmount || 0);

const price = computed(() => useProductPrice(product.value));
const oldPrice = computed(() => getProductOldPrice(product.value));
const totalOldPrice = computed(() => {
  const oldPrice = getProductOldPrice(product.value);
  if (oldPrice) {
    return oldPrice * amount.value;
  }
});
const totalPrice = computed(() => price.value * amount.value);

function checkMinValue() {
  amount.value = props.product.minQuantity ? props.product.minQuantity : 1;
}

watch(initialAmount, (newInitialAmount) => (amount.value = newInitialAmount));
watch(amount, (newAmount) => {
  // сбрасываем значение если значение 0 и ниже
  if (newAmount <= 0) {
    newAmount = 0;
  }
  // Проверяем минимальное к-во
  else if (props.product.minQuantity && newAmount < props.product.minQuantity) {
    amount.value = props.product.minQuantity ? props.product.minQuantity : 1;
  }
  // Проверяем максимальное к-во
  else if (props.product.maxQuantity && newAmount > props.product.maxQuantity) {
    newAmount = props.product.minQuantity ? props.product.minQuantity : 1;
  }

  emit("change", {
    productId: product.value.id,
    newAmount,
  });
});

function preventDecimalInput(event: KeyboardEvent) {
  // Запрещаем ввод точки (46) и запятой (44)
  if (event.key === "." || event.key === ",") {
    event.preventDefault();
  }
}
</script>

<style lang="scss" module>
.order-card {
  background-color: var(--app-action-cards-default);
  border-radius: var(--radius-6xl);
  padding: var(--spacing-md);
  @media screen and (max-width: $sm) {
    padding: 16px 6px 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.compact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .product-data {
    display: flex;
    max-width: 55%;
    align-items: center;
    margin-bottom: 0;
  }
  .product-picture-wrapper {
    position: relative;
  }
  .product-picture {
    margin: 0;
    margin-right: var(--spacing-md);
  }
  .product-discount {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
  .product-name,
  .subtitle {
    text-align: left;
  }
}
.product-data {
  display: flex;
  margin-bottom: var(--spacing-md);
  align-items: center;
  @media screen and (max-width: $sm) {
    display: block;
  }
}
.product-picture {
  display: block;
  width: 64px;
  height: 64px;
  margin-right: 8px;
  flex-shrink: 0;
  border-radius: 20px;
  @media screen and (max-width: $sm) {
    margin: auto;
    margin-bottom: 20px;
  }
}
.product-name,
.subtitle {
  @media screen and (max-width: $sm) {
    text-align: center;
  }
}
.product-titles {
  @media screen and (max-width: $sm) {
    min-height: 30px;
  }
}
.product-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.09px;
  @media screen and (max-width: $md) {
    font-size: 1rem;
  }
  @media screen and (max-width: $sm) {
    font-size: 0.875rem;
  }
}
.subtitle {
  margin-top: 5px;
  color: var(--general-transparent-light-50);
  font-size: 12px;
  line-height: 110%;
  letter-spacing: -0.06px;
  @media screen and (max-width: $sm) {
    margin-top: 4px;
    font-size: 0.5rem;
  }
}
.price-btn {
  width: 100%;
  padding: 0 5px;
  @media screen and (max-width: $md) {
    font-size: 1rem;
  }
  @media screen and (max-width: $sm) {
    height: 54px;
  }
}
.price-discount {
  color: #6b6b6b;
  text-decoration: line-through;
}
.order-card-counter {
  input {
    @media screen and (max-width: $sm) {
      font-size: 1rem;
    }
  }
  button {
    @media screen and (max-width: $sm) {
      width: 46px;
      height: 46px;
      font-size: 0.875rem;
    }
  }
}
.compact-counter {
  width: 160px;
  margin-left: var(--spacing-md);
}
</style>
