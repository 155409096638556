<template>
  <div
    :class="$style['copy_field']"
    @click="copy"
  >
    <div
      :class="$style['copy_field-descr']"
      data-test="copy_field-descr"
    >
      <div :class="$style['copy_field-descr_subtitle']"></div>
      <div
        :class="$style['copy_field-descr_title']"
        data-test="copy_field-value"
      >
        {{ value }} G
      </div>
    </div>
    <IconCopy
      :class="$style['copy_field-copy']"
      data-test="copy_field-copy"
    />
  </div>
</template>
<script setup lang="ts">
import { useClipboard } from "@vueuse/core";

const props = defineProps<{
  value: string | number;
}>();
const { t } = useI18n();
const { value } = toRefs(props);
const notificationStore = useNotificationsStore();
const clipboard = useClipboard();
const copy = async (): Promise<void> => {
  try {
    await clipboard.copy(value.value.toString());
    notificationStore.showNotification({
      text: t("notifications.copy"),
      type: "success",
    });
  } catch (e: any) {
    //
  }
};
</script>

<style module lang="scss">
.copy_field {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md) var(--spacing-2xl);
  border-radius: var(--radius-2xl);
  background: var(--general-transparent-mid-10);
  width: 100%;

  &-descr {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3xs);
    justify-content: center;

    &_subtitle {
      color: var(--general-transparent-light-35);
      font-size: 10px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.05px;
    }

    &_title {
      font-size: 14px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.07px;
    }
  }

  &-copy {
    width: 20px;
    height: 20px;
    fill: white;
    fill-opacity: 0.35;
  }
}
</style>
