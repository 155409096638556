<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.302 11.9124C4.302 11.6766 4.38911 11.4408 4.56391 11.2609L8.70435 6.99996L4.56391 2.73906C4.2149 2.37922 4.2149 1.79588 4.56391 1.43604C4.91352 1.07682 5.4803 1.07682 5.82991 1.43604L10.6027 6.34845C10.9518 6.70828 10.9518 7.29163 10.6027 7.65147L5.82991 12.5639C5.4803 12.9231 4.91352 12.9231 4.56391 12.5639C4.38911 12.384 4.302 12.1482 4.302 11.9124Z"
      fill="currentColor"
    />
  </svg>
</template>
